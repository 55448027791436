import { useState } from 'react';
import './App.css';
import bg from "./res/bg.jpg"

// 1. import `NextUIProvider` component
import { Grid, Input, NextUIProvider, styled } from '@nextui-org/react';

import { createTheme, useTheme, Text, Button, } from '@nextui-org/react';
import { useEffect } from 'react';

const theme = createTheme({
  type: "dark",
  theme: {
    colors: {
      // brand colors
      // primaryLight: '$green200',
      // primary: '#4ADE7B',
      // primaryDark: '$green600',

      // gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
      // link: '#5E1DAD',

      // // you can also create your own color
      // myColor: '#ff4ecd'

      // // ...  more colors
    },
    space: {},
    fonts: {}
  }
})


const Component = () => <Button>Click me</Button>;

function App() {
  return (
    <NextUIProvider theme={theme}>
      <Main />
    </NextUIProvider>
  );
}

const MyButton = styled(Button, {
  borderRadius: '9999px',
  fontSize: '18px',
  border: '0',

  [`.${theme} &`]: {
    backgroundColor: '$accents9',
    color: '$gray900'
  },
});

const CtrlButton= styled(Button, {
  
  width: '56px',
  [`.${theme} &`]: {
    backgroundColor: '$accents9',
    color: '$gray900',
    fontSize: '58px',
    paddingTop: '5px',
    height: '66px',
    minWidth: '126px',
    alignItems: 'flex-start'
  },
});

const ExitBtn= styled(Text, {
  
  width: '56px',
  [`.${theme} &`]: {
    color: '$white',
    fontSize: '18px',
    position: 'absolute',
    bottom: '6px',
    cursor: 'pointer',
  },
  [`.${theme} &:hover`]: {
    color: '$gray300',
  },
});

const Title= styled(Text, {
  
  width: '56px',
  [`.${theme} &`]: {
    color: '$white',
    fontSize: '24px',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: '40px',
    cursor: 'pointer'
  },
});

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

function Main() {
  const [id, setid] = useState('');
  const [event, setEvent] = useState(null);

  useEffect(()=>{

    document.onkeydown = (e) => {
      console.log(e.code)
      if (e.code === "ArrowLeft") {
        sendTrigger('previous')
      }
      if (e.code === "ArrowRight") {
        sendTrigger('next')
      }
    }

    let token = localStorage.getItem('presenter-token');
    if (token) {
      let tokenParsed = parseJwt(token);
      verifyCode(tokenParsed);
    }  else setEvent(null);
  },[]);

  function verifyCode (token = false) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        id: token?.id || id
      }),
      redirect: 'follow'
    };

    
    fetch("https://presenter.soolutions.net/api/web-client/auth", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.token) {
          setEvent(result);
          localStorage.setItem('presenter-token', result.token);
        }
      })
      .catch(error => {
        console.log('error! ', error);
        setid('');
        setEvent(null);
        localStorage.removeItem('presenter-token');
      });
  }

  function sendTrigger (action) {
    let token = localStorage.getItem('presenter-token');
    if (!token) return setEvent(null);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-auth-token", token);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        shape: "trigger",
        action
      }),
      redirect: 'follow'
    };

    
    fetch("https://presenter.soolutions.net/api/web-client/action", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log('error! ', error);
        setid('');
        setEvent(null);
        localStorage.removeItem('presenter-token');
      });
  }

  function disconnect () {
        setEvent(null);
        localStorage.removeItem('presenter-token');
  }
  return (
    <div className={theme} style={{backgroundImage:`url(${bg})`}}>
    <div className="main">
    {
      event? <div className="ctrl-box">
      <Title>{event.name}</Title>
        <CtrlButton onClick={()=>{sendTrigger('previous')}}>{'<'}</CtrlButton>
        <CtrlButton onClick={()=>{sendTrigger('next')}}>{'>'}</CtrlButton>
        <ExitBtn onClick={()=>{disconnect()}}>disconnect</ExitBtn>
      </div>
      :
      <div className="login-box">
      <Input rounded bordered label="Presenter Code" placeholder="type code here..." color="default"
      value={id}
      onChange={(e)=>{
        setid(e.target.value)
      }}/>
        <MyButton onClick={()=>{verifyCode()}}>Start</MyButton>
      </div>
    }
    
    </div>
    </div>
  )
};

export default App;
